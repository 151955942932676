<template>
  <div :class="['header-container',{'open-overlay':overlayOpen}]">
    <div class="container-1">
      <div class="header-div">
        <header-content />
      </div>
    </div>
  </div>
</template>

<script>
import { EventBus } from "@/main";
export default {
  name: "HeaderDark",
  data(){
    return {
      overlayOpen:false
    }
  },
  methods:{
    switchView(val){
      this.overlayOpen=val;
    }
  },
  created(){
    EventBus.$on('toggle-dropdown-view',this.switchView);
  },
  beforeDestroy(){
    EventBus.$off('toggle-dropdown-view',this.switchView);
  },
  components: {
    "header-content": () =>
      import(/* webpackChunkName: "Header" */ "@/components/Header/Header.vue")
  }
};
</script>

<style lang="scss" scoped>
@import "~sass/modules/components";
@import "~sass/modules/_variables.scss";
@import "~sass/modules/_mediaQueries.scss";

.header-container {
  background: $clr-bg-black 0% 0% no-repeat padding-box;
  min-height: $header-total-height;
  height: $header-total-height;
  position: relative;
  z-index:3;
  .header-div{
    margin: 0px;
  }

  .container-1{
    margin: auto;
    height:100%;
    max-width: 1200px;
  }

  .header-div{
    height:100%;
  }
}

//(max-width:480px)

@include breakpoint(max480){
  .header-container.open-overlay{
    position:fixed;
    top:0;
    left:0;
    width:100%;
  }
}


</style>